import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams
} from "react-router-dom";
import Login, { getQueryParams } from "../components/Auth/Login/Login";
import YouChat from "../pages/YouChat/YouChat";
import React, {
  useCallback,
  useEffect,
  useMemo
} from "react";
import PrivateRoute from "./PrivateRoute";
import {useAppDispatch, useAppSelector} from "@/hooks/appHook";
import {
  getUser,
  setUser
} from "@/store/user/user";
import {setSize as setScreenSize} from '@/store/screenSize/screenSize';
import {getLang} from "@/store/language/language";
import { queries } from '@/constants/general';

const Router = () => {
  const user = useAppSelector(getUser)
  const dispatch = useAppDispatch()
  const lang = useAppSelector(getLang)

  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const sso = useMemo<boolean>(
    () => {
      const memberId = searchParams.get(queries.memberId)
      const token = searchParams.get(queries.otp)
      return !!(memberId
        && token
        && location.pathname.replace(/\/?$/, '').endsWith('login'))
    },
    [location.pathname, searchParams]
  )

  const isLoggedIn = !!user || sso

  useEffect(() => {
    if (!sso) {
      return
    }
    const memberId = searchParams.get(queries.memberId)
    const token = searchParams.get(queries.otp)
    if (!(memberId && token)) {
      return;
    }

    setSearchParams(prev => {
      prev.delete(queries.memberId)
      prev.delete(queries.otp)
      return prev
    })
    dispatch(setUser({
      memberId,
      accessToken: token,
      sso: true,
      isAdmin: false,
      canBroadcast: false
    }))

  }, [location, dispatch, searchParams, setSearchParams, sso]);


  const updateScreenSize = useCallback(() => {
    dispatch(setScreenSize({
      width: window.innerWidth || document.documentElement.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight
    }))
  }, [dispatch])

  useEffect(() => {
    updateScreenSize()
    window.addEventListener('resize', updateScreenSize)
    return () => {
      window.removeEventListener('resize', updateScreenSize)
    }
  }, [updateScreenSize])

  if (sso) {
    return <Navigate to={`/` + lang + getQueryParams()} replace />
  }

  return (
    <Routes>
      <Route path="/:langParam?" element={<PrivateRoute redirectPath={"/"+lang+"/login"} isLoggedIn={!!isLoggedIn}>
        <YouChat lang={lang} />
      </PrivateRoute>}/>
      <Route path="/:langParam?/login" element={<Login isLoggedIn={!!isLoggedIn} lang={lang}/>}/>
      <Route path="/:langParam?" element={<Login isLoggedIn={!!isLoggedIn} lang={lang}/>}/>
    </Routes>
  )
}

export default Router
