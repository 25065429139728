import Messages from '@/services/Messages';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {Chat, getActiveChatId, getAllChats, getChat} from '@/store/chats/chats';
import React, {
  ChangeEvent,
  ChangeEventHandler, ClipboardEventHandler,
  KeyboardEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {getUser} from '@/store/user/user';
import useMessage from '@/hooks/useMessage';
import classNames from 'classnames';
import TextareaAutosize from '@gry-dmitrij/react-textarea-autosize';
import styles from './InputBox.module.scss'
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import useContextMenu, {MENU_POSITIONS} from '@/components/Menu/ContextMenu/useContextMenu';
import {FormattedMessage, useIntl} from 'react-intl';
import {isFileImageSupported} from '@/utils/image';
import {v4 as uuid} from 'uuid'
import {
  add,
  getReplyMessage,
  IAudioMessage,
  IImageMessage,
  remove,
  setReply,
  update,
} from '@/store/messages/messages';
import {uploadAudio, uploadImage} from '@/api/upload';
import ReplyMessage from './ReplyMessage/ReplyMessage';
import MediaRecordingService from '@/services/MediaRecordingService';
import {formatMinuteTime} from '@/utils/dateutils';
import {show as showNotify} from '@/components/Modal/UserNotify/UserNotify';
import EmojiPicker, {EmojiPickerProps} from '@/components/EmojiPicker/EmojiPicker';
import {Sticker} from '@/store/stickers/stickers';
import {createPubSub} from '@inficen/react-events';
import {getDraft, remove as removeDraft, update as updateDraft} from '@/store/draft/draft';
import {isTouchDevice} from '@/utils/device';
import {CustomEvents} from '@/interfaces/general';
import {checkOwner, getNameOrNick, isTeamChannel} from '@/utils/chats';
import useCachedChat from '@/hooks/useCachedChat';
import { formatFileSize, isAudioFile, isVideoFile } from '@/utils/file';

const mediaDevicesAvailable = !!navigator.mediaDevices;

const MIN_AUDIO_SIZE = 4096
const MAX_SIZE_FILE: number = +(process.env.REACT_APP_MAX_FILE_YC || 0);
const USER_MESSAGE_TIMEOUT = 5000

type Event = {
  name: string,
  payload: {
    acceptedFiles: File[],
    chat: Chat
  }
}

const {publish, useSubscribe} = createPubSub<Event>()

const FORWARD_EVENT = 'sendFiles'
const NAME_EVENT = 'INPUT_BOX'
export const sendFiles = (acceptedFiles: File[], chat: Chat) => {
  const nameEvent: string = `${NAME_EVENT}/${FORWARD_EVENT}`
  publish(nameEvent, {acceptedFiles, chat})
}

interface InputBoxProps {
  className?: string
}

const createMessageEvent = () => {
  return new CustomEvent(CustomEvents.MESSAGE_SENT_TO_CURRENT_CHAT)
}

const dispatchMessageEvent = () => {
  window.dispatchEvent(createMessageEvent())
}

const formatAudioError = (err: string): string => {
  switch (err) {
    case 'Empty blob.':
      return 'too_short_record';
    default:
      return err
  }
}

const InputBox = ({className}: InputBoxProps) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const activeChatId = useAppSelector(getActiveChatId) || ''
  const chat = useAppSelector(getChat(activeChatId))
  const replyMessage = useAppSelector(getReplyMessage(activeChatId || ''))
  const fromReply = useAppSelector(getChat(replyMessage?.from || ''))
  const draft = useAppSelector(getDraft(activeChatId))
  const allChats = useAppSelector(getAllChats)
  const currentChat = useAppSelector(getChat(activeChatId))
  const [text, setText] = useState('')
  const [micEnabled, setMicEnabled] = useState(false)
  const [recording, setRecording] = useState(false)
  const [recordTime, setRecordTime] = useState(0)
  const recordingTimer = useRef<NodeJS.Timer>()
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const fileInput = useRef<HTMLInputElement>(null)
  const {sendMessage, sendFile, sendVideo} = useMessage()
  const {formatMessage} = useIntl()
  const textRef = useRef(text)
  const draftRef = useRef(draft)
  const fileButtonRef = useRef<HTMLButtonElement>(null)
  const micButtonRef = useRef<HTMLButtonElement>(null)
  const emojiButtonRef = useRef<HTMLButtonElement>(null)
  const emojiPickerActive = useRef(false)
  const handleDropFile = useRef<Function>()
  const {cachedChat} = useCachedChat({jid: activeChatId})

  useEffect(() => {
    if (textAreaRef.current && !isTouchDevice()) {
      textAreaRef.current.focus()
    }
  }, [activeChatId])

  useEffect(() => {
    textRef.current = text
  }, [text])

  useEffect(() => {
    draftRef.current = draft
  }, [draft])

  useEffect(() => {
    if (!currentChat || (currentChat?.type === 'chat' && currentChat?.$subscription !== 'both')) {
      return
    }
    const jid = currentChat.$jid
    return () => {
      dispatch(updateDraft({jid, draft: textRef.current}))
    }
  }, [currentChat, dispatch])

  useEffect(() => {
    setText(draftRef.current || '')
  }, [activeChatId, dispatch])

  useEffect(() => {
    if (!draftRef.current || text === draftRef.current) {
      return
    }
    dispatch(removeDraft(activeChatId))
  }, [text, dispatch])  // eslint-disable-line  react-hooks/exhaustive-deps

  const onHideEmoji = () => {
    emojiPickerActive.current = false
  }

  const {
    show: showEmojiPicker,
    ContextMenu: EmojiPickerContextMenu,
    hide: hideEmojiPicker,
  } = useContextMenu<EmojiPickerProps>({
    Component: EmojiPicker,
    componentProps: {},
    options: {
      positions: [MENU_POSITIONS.RIGHT],
    },
    onHide: onHideEmoji,
  })

  const onEmojiSelect = useCallback((emoji: string) => {
    setText(prevState => prevState + emoji)
    hideEmojiPicker()
  }, [hideEmojiPicker])

  const onStickerSelect = useCallback((sticker: Sticker) => {
    if (!user?.$jid || !chat) {
      hideEmojiPicker()
      return
    }
    const msg = Messages.Messages.createStickerMessage({
      from: user.$jid,
      to: chat.$jid,
      isRoom: chat.type === 'groupchat',
      sticker,
      replyMessage,
    })
    dispatch(setReply({jid: activeChatId || '', replyMessage: null}))
    sendMessage(msg)
    hideEmojiPicker()
    dispatchMessageEvent()
  }, [hideEmojiPicker, user?.$jid, sendMessage, chat, activeChatId, replyMessage, dispatch])

  const handleShowEmojiPicker: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    const position = {
      x: e.pageX,
      y: e.pageY,
    }
    showEmojiPicker({
      position,
      componentProps: {
        onEmojiSelect,
        onStickerSelect,
      },
    })
    emojiPickerActive.current = true
  }

  useEffect(() => {
    if (replyMessage != null) {
      textAreaRef.current?.focus()
    }
  }, [replyMessage])

  const startAudioRecord = useCallback(() => {
    MediaRecordingService.recordAudio()
      .then(() => {
        setRecordTime(0)
        setRecording(true)
      })
      .catch((err) => {
        setRecording(false)
        setMicEnabled(false)
      })
  }, [])

  const stopAudioRecord = useCallback(async () => {
    MediaRecordingService.stopRecord()
  }, [])

  const sendAudio = useCallback(async (blob: Blob | File) => {
    const to = chat?.$jid
    const currentChat = chat
    if (!user?.$jid || !to) {
      return
    }

    const uid = uuid()
    const reply = replyMessage
    const audioMessage: IAudioMessage = {
      from: user.$jid,
      to,
      id: uid,
      type: 'audio',
      status: 'sending',
      url: '',
      ...(blob instanceof File && {name: blob.name}),
      timestamp: new Date().getTime() * 1000,
      ...(reply && {replyMessage: reply}),
    }
    dispatch(update({jid: to, messages: [audioMessage]}))
    dispatch(setReply({jid: activeChatId || '', replyMessage: null}))
    dispatchMessageEvent()
    try {
      const {url} = await uploadAudio({uid, blob})
      const msg = Messages.Messages.createAudioMessage({
        from: user.$jid,
        to,
        isRoom: currentChat?.type === 'groupchat',
        uid,
        url,
        ...(blob instanceof File && {name: blob.name}),
        replyMessage: reply,
      })
      sendMessage(msg, false)
      const sentMessage: IAudioMessage = {
        ...audioMessage,
        status: 'sent',
        url,
      }
      dispatch(update({jid: to, messages: [sentMessage]}))
    } catch (e) {
      showNotify({
        message: formatMessage({id: 'file_was_not_sent_out'}),
        timeout: USER_MESSAGE_TIMEOUT,
      })
      dispatch(remove({jid: to, message: audioMessage}))
      console.error(e) // eslint-disable-line no-console
    }
  }, [activeChatId, chat, replyMessage, sendMessage, user?.$jid, dispatch, formatMessage])

  const onStopRecord = useCallback(async (blob: Blob) => {
    setRecording(false)
    if (blob.size < MIN_AUDIO_SIZE) {
      showNotify({
        message: formatMessage({id: 'too_short_record'}),
      })
      return
    }
    sendAudio(blob)
  }, [sendAudio, formatMessage])

  const onErrorRecord = useCallback((err: string) => {
    showNotify({
      message: formatMessage({id: formatAudioError(err)}),
    })
    setRecording(false)
    setMicEnabled(false)
  }, [formatMessage])

  useEffect(() => {
    MediaRecordingService.addListener('stop', onStopRecord)
    return () => {
      MediaRecordingService.removeListener('stop', onStopRecord)
    }
  }, [onStopRecord])

  useEffect(() => {
    if (recording) {
      if (!recordingTimer.current) {
        recordingTimer.current = setInterval(() => {
          setRecordTime(prevState => prevState + 1)
        }, 1000)
      }
    } else {
      clearInterval(recordingTimer.current)
      recordingTimer.current = undefined
    }
  }, [recording])

  useEffect(() => {
    MediaRecordingService.addListener('error', onErrorRecord)
    return () => {
      MediaRecordingService.removeListener('error', onErrorRecord)
    }
  }, [onErrorRecord])

  useEffect(() => {
    if (micEnabled && !recording) {
      startAudioRecord()
    } else if (!micEnabled && recording) {
      stopAudioRecord()
    }
  }, [micEnabled, recording, startAudioRecord, stopAudioRecord])

  const sendImage = useCallback(async (file: File) => {
    if (!chat) {
      return
    }
    const uid = uuid()
    const fileUrl = URL.createObjectURL(file)
    const reply = replyMessage
    const message: IImageMessage = {
      id: uid,
      from: user?.$jid || '',
      to: activeChatId,
      type: 'image',
      status: 'sending',
      timestamp: new Date().getTime() * 1000,
      image: {
        url: fileUrl,
        thumbnail: fileUrl,
      },
      ...(reply ? {replyMessage: reply} : null),
    }
    dispatch(add({jid: activeChatId, message}))
    dispatch(setReply({jid: activeChatId || '', replyMessage: null}))
    const {url, thumbnail} = await uploadImage({file, uid})
    const stanza = Messages.Messages.createImageMessage(
      {
        from: user?.$jid || '',
        to: activeChatId,
        isRoom: chat.type === 'groupchat',
        thread: uid,
        uid,
        url,
        thumbnail,
        replyMessage: reply,
      },
    )

    if (sendMessage(stanza, false)) {
      const imageMessage: IImageMessage = {
        ...message,
        status: 'sent',
        image: {
          url,
          thumbnail,
        },
      }
      const image = new Image()
      image.src = thumbnail
      image.onload = () => {
        dispatch(update({jid: activeChatId, messages: [imageMessage]}))
      }
    }
  }, [activeChatId, chat, dispatch, sendMessage, user?.$jid, replyMessage])

  const sendText = () => {
    if (!chat || !text.trim()) {
      return
    }
    const msg = Messages.Messages.createTextMessage({
      text: text,
      from: user?.$jid || '',
      to: activeChatId,
      thread: chat.thread,
      isRoom: chat.type === 'groupchat',
      replyMessage,
    })
    sendMessage(msg)
    setText('')
    dispatch(setReply({jid: activeChatId || '', replyMessage: null}))
    dispatchMessageEvent()
  }

  const handleText = (e: ChangeEvent<HTMLTextAreaElement> | undefined) => {
    if (e) {
      setText(e?.target.value)
    }
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      // Have Shift-Enter insert a line break instead
      if (!e.shiftKey) {
        e.preventDefault();
        sendText()
      }
    }
  }

  const sendVideoFile = useCallback((file: File) => {
    if (!user?.$jid || !chat) {
      return
    }
    sendVideo({
      file,
      userJid: user.$jid,
      toChat: chat,
      replyMessage: replyMessage || undefined,
    })
  }, [user?.$jid, chat, replyMessage, sendVideo])

  const handleSendFile = useCallback((file: File, contact?: Chat) => {
    const toChat = contact || chat
    if (file.size > MAX_SIZE_FILE) {
      showNotify({
        message: formatMessage({id: 'file_attachment_too_large'}, {
          size: formatFileSize(file.size) as string,
          limit: formatFileSize(MAX_SIZE_FILE) as string,
        }),
      })
      return
    }

    if (!user?.$jid || !toChat) {
      return
    }
    if (isFileImageSupported(file)) {
      sendImage(file)
    } else if (isAudioFile(file)) {
      sendAudio(file)
    } else if (isVideoFile(file)) {
      sendVideoFile(file)
    } else {
      sendFile({
        file,
        toChat,
        userJid: user.$jid,
        replyMessage: replyMessage || undefined,
      })
    }
    dispatch(setReply({jid: activeChatId || '', replyMessage: null}))
    dispatchMessageEvent()
  }, [
    user?.$jid,
    activeChatId,
    chat,
    formatMessage,
    replyMessage,
    sendImage,
    sendAudio,
    sendVideoFile,
    sendFile,
    dispatch,
  ])

  const onPaste: ClipboardEventHandler<HTMLTextAreaElement> = (e) => {
    const files = e.clipboardData.files
    if (files.length) {
      handleSendFile(files[0])
    }
  }

  const handleSelectFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }
    handleSendFile(file)
    if (fileInput.current) {
      fileInput.current.value = ''
    }
  }

  const handleInputClick = () => {
    if (!isTouchDevice()) {
      textAreaRef.current?.focus()
    }
  }

  handleDropFile.current = useCallback((acceptedFiles: File[], chat: Chat) => {
    if (acceptedFiles.length) {
      handleSendFile(acceptedFiles[0], chat)
    }
  }, [handleSendFile])

  useSubscribe(`${NAME_EVENT}/${FORWARD_EVENT}`, ({acceptedFiles, chat}) => {
    handleDropFile.current?.(acceptedFiles, chat)
  })

  const clearReplyMessage = () => {
    dispatch(setReply({jid: activeChatId || '', replyMessage: null}))
  }

  if (chat?.muted === true ||
    (isTeamChannel(cachedChat) && !checkOwner(user?.$jid || '', cachedChat?.members || []))) {
    return <div className={classNames(styles.inputBox, styles.disabled, className)}>
      <FormattedMessage id={'CHAT.INPUT_MESSAGE_DISABLED'}>
        {txt => <p className={styles.stubText}>{txt}</p>}
      </FormattedMessage>

    </div>
  }

  const enableMic = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation()
    setMicEnabled(true)
  }

  const disableMic = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation()
    setMicEnabled(false)
  }

  const handleInputBlur: React.FocusEventHandler = (event) => {
    const targets: (Element | null)[] = [
      fileButtonRef.current,
      micButtonRef.current,
      emojiButtonRef.current,
    ]
    if (targets.includes(event.relatedTarget) || emojiPickerActive.current) {
      textAreaRef.current?.focus()
      return
    }
  }

  const handleContainerClick: React.MouseEventHandler = (event) => {
    const targets: (Element | null)[] = [fileInput.current]
    if (targets.includes(event.target as Element)) {
      return
    }
    textAreaRef.current?.focus()
  }

  const getFromName = () => {
    if (replyMessage && replyMessage.from === user?.$jid) {
      return user.nickname
    }

    if (replyMessage && replyMessage.from && allChats[replyMessage.from]) {
      return getNameOrNick(allChats[replyMessage.from])
    }

    return fromReply?.name
  }

  const handleOpenFile = (e: React.MouseEvent) => {
    e.stopPropagation()
    fileInput.current?.click()
  }

  return <div
    className={classNames(styles.inputBox, className)}
    onClick={handleContainerClick}
  >
    {replyMessage && <ReplyMessage
      msg={replyMessage}
      fromName={getFromName()}
      onClose={clearReplyMessage}
    />}

    <div className={styles.content}>
      <IconButton
        tabIndex={1}
        ref={fileButtonRef}
        className={classNames(styles.iconButton, recording && 'visually-hidden')}
        onClick={handleOpenFile}
      >
        <i className="chat-clip"/>
      </IconButton>
      <TextareaAutosize
        tabIndex={0}
        ref={textAreaRef}
        className={classNames(styles.inputField, recording && 'visually-hidden')}
        minRows={1}
        maxRows={8}
        value={text}
        placeholder={formatMessage({id: 'CHAT.TYPE_MESSAGE'})}
        onChange={handleText}
        onKeyDown={handleKeyPress}
        onPaste={onPaste}
        onBlur={handleInputBlur}
      />
      <IconButton
        tabIndex={2}
        ref={emojiButtonRef}
        className={classNames(styles.iconButton, recording && 'visually-hidden')}
        onClick={handleShowEmojiPicker}
      >
        <i className={'chat-smile'}/>
      </IconButton>
      {(!!text || !mediaDevicesAvailable) && <IconButton
        className={classNames(styles.iconButton, styles.sendButton, recording && 'visually-hidden')}
        onClick={sendText}
      >
        <i className={classNames('chat-send')}/>
      </IconButton>}
      {recording && <>
        <i className={classNames('chat-a-record', styles.iconButton, styles.recordMark)}></i>
        <span className={styles.recordText}>{formatMinuteTime(recordTime)}</span>
        <FormattedMessage id="releaseToStopRecording">
          {txt => <p className={styles.recordText}>{txt}</p>}
        </FormattedMessage>
      </>}
      {!text && mediaDevicesAvailable && <IconButton
        ref={micButtonRef}
        className={classNames(styles.iconButton, styles.micBtn, recording && styles.active)}
        onMouseDown={enableMic}
        onMouseUp={disableMic}
        onMouseLeave={disableMic}
        onTouchStart={enableMic}
        onTouchEnd={disableMic}
        onClick={e => e.stopPropagation()}
      >
        <i className={'chat-mic'}/>
      </IconButton>}
    </div>
    <EmojiPickerContextMenu/>

    <input
      ref={fileInput}
      className="d-none"
      type="file"
      id="input-file"
      onChange={handleSelectFile}
      onClick={handleInputClick}
    />
  </div>
}

export default InputBox

