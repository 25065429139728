import React, {useState, useRef} from 'react';
import styles from './Lang.module.scss';
import classNames from "classnames/bind";
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {Link} from "react-router-dom";
import {getLang, setLang} from "@/store/language/language";

const cx = classNames.bind(styles);

const Lang = () => {
  const main = useRef<HTMLDivElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);
  const lang = useAppSelector(getLang)
  const [dropdownShown, setDropdownShown] = useState(false);
  const dispatch = useAppDispatch()

  React.useEffect(() => {
      if (dropdownShown) {
        const handler = () => {
            setDropdownShown(false);
        };
        setTimeout(() => {
          document.addEventListener('click', handler);
        }, 100);

        return () => {
          document.removeEventListener('click', handler);
        }
      }
  }, [dropdownShown])

  const handleDropdown = (e: any) => {
    if (e.target === dropdown.current) {
      return;
    }
    setDropdownShown(!dropdownShown);
  }

  return (
    <div className={styles.main} onClick={handleDropdown} ref={main}>
      <span>{lang.toUpperCase()}</span>
      <i className={cx("chevron", {"chat-chevron-down": !dropdownShown, "chat-chevron-up": dropdownShown})} />
      <div className={cx(styles.dropdown, {shown: dropdownShown})} ref={dropdown}>
        <Link className={cx({active: lang === 'en'})}
              onClick={() => dispatch(setLang('en'))} to={'/en/login'}>English</Link>
        <Link className={cx({active: lang === 'cn'})}
              onClick={() => dispatch(setLang('cn'))} to={'/cn/login'}>简体中文</Link>
        <Link className={cx({active: lang === 'tc'})}
              onClick={() => dispatch(setLang('tc'))} to={'/tc/login'}>繁體中文</Link>
        <Link className={cx({active: lang === 'kr'})}
              onClick={() => dispatch(setLang('kr'))} to={'/kr/login'}>한국어</Link>
        <Link className={cx({active: lang === 'jp'})}
              onClick={() => dispatch(setLang('jp'))} to={'/jp/login'}>日本語</Link>
      </div>
    </div>
  )
}

export default Lang;
