import Search from '@/components/Primitive/Inputs/Search/Search';
import styles from './ChatActionPanel.module.scss'
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import useContextMenu from '@/components/Menu/ContextMenu/useContextMenu';
import ContextMenuList, {ContextMenuProps} from '@/components/Menu/ContextMenu/ContextMenuList';
import {ComponentProps, MouseEventHandler} from 'react';
import {useIntl} from 'react-intl';
import {useModal} from '@/hooks/useModal';
import AddContact from '@/components/Modal/AddContact/AddContact';
import CreateGroupChat from '@/components/Modal/CreateGroupChat/CreateGroupChat';
import LoginAsMember from '@/components/Modal/LoginAsMember/LoginAsMember';
import Statistics from '@/components/Modal/Statistics/Statistics';
import {getUser} from "@/store/user/user";
import {useAppSelector} from "@/hooks/appHook";
import useModalSimple from '@/hooks/useModalSimple';
import Broadcast from "@/components/Modal/Broadcast/Broadcast";
import {ErrorFactory} from '@/services/errorFactory';

const ACTIONS = {
  ADD_CONTACT: 'add-contact',
  CREATE_GROUP_CHAT: 'create-group-chat',
  LOGIN_AS_MEMBER: 'login-as-member',
  STATISTICS: 'statistics',
  BROADCAST: 'broadcast',
}

const contextMenuList = {
  list: [
    {text: 'ACTION.ADD_CONTACT', action: ACTIONS.ADD_CONTACT, icon: 'chat-add-contact'},
    {text: 'GROUP_CHAT.CREATE_NEW', action: ACTIONS.CREATE_GROUP_CHAT, icon: 'chat-add-group'},
  ]
}

interface ChatActionProps {
  onChange?: (filter: string) => void
}

const ChatActionPanel = ({onChange}: ChatActionProps = {}) => {
  const user = useAppSelector(getUser)
  const {formatMessage} = useIntl()

  const {show: showAddContact, hide: hideAddContact, visible: visibleAddContact} = useModalSimple()
  const {show: showCreateGroup, Modal: CreateGroupModal} =
    useModal<ComponentProps<typeof CreateGroupChat>>(CreateGroupChat)
  const {show: showLoginAsMember, Modal: LoginAsMemberModal} =
    useModal<ComponentProps<typeof LoginAsMember>>(LoginAsMember)
  const {show: showStatistics, Modal: StatisticsModal} =
    useModal<ComponentProps<typeof Statistics>>(Statistics)
  const {visible: broadcastVisible, hide: hideBroadcast, show: showBroadcast} = useModalSimple()

  const onClickContextMenu = (action: string) => {
    switch (action) {
      case ACTIONS.ADD_CONTACT:
        showAddContact()
        break
      case ACTIONS.CREATE_GROUP_CHAT:
        showCreateGroup()
        break
      case ACTIONS.LOGIN_AS_MEMBER:
        showLoginAsMember()
        break
      case ACTIONS.STATISTICS:
        showStatistics()
        break
      case ACTIONS.BROADCAST:
        showBroadcast()
        break
      default:
        throw ErrorFactory.createActionNotImplemented(action)
    }
  }

  const {show: showContextMenu, ContextMenu} = useContextMenu<ContextMenuProps>({
    Component: ContextMenuList,
    componentProps: {
      list: contextMenuList.list.map(item => ({
        ...item,
        text: formatMessage({id: item.text})
      })),
    }
  })

  const handleShowMenu: MouseEventHandler<HTMLButtonElement> = (e) => {
    const list = contextMenuList.list.map(item => ({
        ...item,
        text: formatMessage({id: item.text})
      }))
    if (user?.isAdmin) {
      list.push({
        text: formatMessage({id: 'LOGIN.LOGIN_AS_MEMBER'}),
        action: ACTIONS.LOGIN_AS_MEMBER, icon: 'chat-members'
      })
      list.push({
        text: formatMessage({id: 'STATISTICS'}),
        action: ACTIONS.STATISTICS, icon: 'chat-statistics'
      })
    }
    if (user?.canBroadcast) {
      list.push({
        text: formatMessage({id: 'ACTION.BROADCAST'}),
        action: ACTIONS.BROADCAST, icon: 'chat-horn'
      })
    }
    const position = {
      x: e.pageX,
      y: e.pageY
    }
    showContextMenu({
      position,
      componentProps: {
        list,
        onClick: onClickContextMenu
      }
    })
  }

  return <div className={styles.panel}>
    <Search wrapperClassName={styles.search} onChange={onChange} size={5}/>
    <IconButton
      className={styles.button}
      onClick={handleShowMenu}
    ><i className={'chat-plus'}></i>
    </IconButton>
    <ContextMenu />
    {visibleAddContact && <AddContact hide={hideAddContact}/>}
    <CreateGroupModal />
    <LoginAsMemberModal />
    <StatisticsModal />
    {broadcastVisible ? <Broadcast
      hide={hideBroadcast}
    /> : null}
  </div>
}

export default ChatActionPanel
